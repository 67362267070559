<template>
  <b-modal v-model="modaltracking" :title="  $t('components.trackingNumber.title') " content-class="card "
    header-class="card-header" @hidden="fnResetShippingInfo()" cancel-variant="danger" size="xl"
    :hide-footer="internationalInformation" hide-header-close>

    <div v-if="orderInformation">

      <div v-if=" !creatingTrackingNumber ">

        <div v-if=" ecarttrackingNumber.tracking_number ">

          <div class="row">
            <div class="col-12 col-xl-6 ">
              <div class="card">
                <div class="card-header ">
                  <h5 v-text="$t('dashboard.order.shipping.origin')"> </h5>
                </div>
                <div class="card-body order-address-body" v-if="originAddress">
                  <p class="m-0">
                    <span
                      v-text=" $t('tables.name') + ': ' + originAddress.first_name + ' ' + originAddress.last_name "></span>
                  </p>
                  <p class="m-0">
                    <span v-text=" $t('tables.phone') + ': ' + originAddress.phone "></span>
                  </p>
                  <p class="m-0 ">
                    <span v-text=" $t('tables.address') + ': ' "></span>
                    <span v-text="fnFormatAddress( originAddress )"></span>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-xl-6 ">
              <div class="card">
                <div class="card-header ">
                  <h5 v-text="$t('dashboard.order.shipping.destination')"> </h5>
                  <div class="d-flex align-items-center ">
                  </div>
                </div>
                <div class="card-body order-address-body " v-if="orderInformation.shipping_address">
                  <p class="m-0">
                    <span
                      v-text=" $t('tables.name') + ': ' + orderInformation.shipping_address.first_name + ' ' + orderInformation.shipping_address.last_name "></span>
                  </p>
                  <p class="m-0">
                    <span v-text=" $t('tables.phone') + ': ' + orderInformation.shipping_address.phone "></span>
                  </p>
                  <p class="m-0 ">
                    <span v-text=" $t('tables.address') + ': ' "></span>
                    <span v-text="fnFormatAddress( orderInformation.shipping_address )"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div v-else>

          <div v-show=" trackingType == 'byAddress' ">

            <div class=" animated fadeIn " v-show=" !internationalInformation ">
              <div class="row">
                <div class="col-12 col-xl-6 ">
                  <div class="card">
                    <div class="card-header order-address-header">
                      <h5 v-text="$t('dashboard.order.shipping.origin')">
                      </h5>
                      <!-- <p class="card-title text-muted " v-text="$t('dashboard.order.shipping.originMessage')"> </p> -->

                      <v-observer tag="form" class=" animated fadeIn " ref="formAddress"
                        @submit.prevent="fnValidateAddress">
                        <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.address') ">
                          <div class="form-group mb-0">
                            <label v-text="$t('dashboard.order.shipping.originMessage')"> </label>
                            <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                              <select class="custom-select" @change="fnChangeAddress()"
                                :class=" { 'is-invalid ':errors.length > 0 } " v-model="tempAddressId">
                                <option :value="null" v-text=" $t('general.form.select') ">
                                </option>
                                <option :value="address.id" v-for=" (address,index) in addressesList " :key="index"
                                  v-text="address.address_name">
                                </option>
                              </select>
                              <div class="input-group-append">
                                <label class="input-group-text"> <i class="fa fa-warehouse "></i> </label>
                              </div>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </v-validation>
                        <input type="submit" hidden>
                      </v-observer>

                    </div>
                    <div class="card-body">
                      <div class="d-flex flex-column justify-content-between order-address-body">

                        <div class="row" v-if="tempAddressId">
                          <div class="col-12">
                            <p class="m-0">
                              <span
                                v-text=" $t('tables.name') + ': ' + tempAddress.first_name + ' ' + tempAddress.last_name "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0"> <span v-text=" $t('tables.phone') + ': ' + tempAddress.phone "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0">
                              <span v-text=" $t('tables.address') + ': ' "></span>
                              <span v-text="fnFormatAddress( tempAddress )"></span>
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <p>
                            <i class="fa fa-exclamation-triangle text-warning mr-1 "></i>
                            <span v-text="$t('dashboard.order.shipping.selectAddressMessage') + ' ' "></span>
                            <router-link :to=" '/dashboard/stores/' + storeInformation.id " class="text-lowercase"
                              v-text="$t('general.button.here')"></router-link>.
                          </p>
                        </div>
                        <p class="text-right" v-if="tempAddressId">
                          <button class="btn btn-link p-0 " :class=" newAddresses.origin ? 'text-muted':''  "
                            v-text=" newAddresses.origin ? $t('general.button.canelEdition') : $t('general.button.editAddress')  "
                            @click=" newAddresses.origin = !newAddresses.origin "></button>
                        </p>

                      </div>

                      <div v-if="newAddresses.origin">
                        <hr>
                        <AddressForm :addressInformation="tempAddress" :disabledList="disabledFields.origin"
                          ref="originAddress" />
                      </div>


                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6  ">

                  <div class="card">
                    <div class="card-header order-address-header ">
                      <h5 v-text="$t('dashboard.order.shipping.destination')">
                      </h5>
                      <p class="card-title text-muted" v-text="$t('dashboard.order.shipping.destinationMessage')"> </p>
                      <div class="d-flex align-items-center ">
                      </div>
                    </div>
                    <div class="card-body ">

                      <div class=" d-flex flex-column justify-content-between order-address-body">
                        <div class="row" v-if="orderInformation.shipping_address">
                          <div class="col-12">
                            <p class="m-0"> <span
                                v-text=" $t('tables.name') + ': ' + orderInformation.shipping_address.first_name + ' ' + orderInformation.shipping_address.last_name "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0"> <span
                                v-text=" $t('tables.phone') + ': ' + orderInformation.shipping_address.phone "></span>
                            </p>
                          </div>
                          <div class="col-12">
                            <p class="m-0 text-truncate">
                              <span v-text=" $t('tables.address') + ': ' "></span>
                              <span v-text="fnFormatAddress( orderInformation.shipping_address )"></span>
                            </p>
                          </div>
                        </div>
                        <p class="text-right">
                          <button class="btn btn-link p-0" :class=" newAddresses.shippingAddress ? 'text-muted':''  "
                            v-text=" newAddresses.shippingAddress ? $t('general.button.canelEdition') : $t('general.button.editAddress')  "
                            @click=" newAddresses.shippingAddress = !newAddresses.shippingAddress "></button>
                        </p>
                      </div>

                      <div v-if="newAddresses.shippingAddress">
                        <hr>
                        <AddressForm :disabledList="disabledFields.shippingAddress"
                          :addressInformation="orderInformation.shipping_address" ref="shippingAddress" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <OrderInternational :orderInformation="orderInformation" @update-shiping="fnApiUpdateAddresses()"
              v-show=" internationalInformation " />
          </div>

          <div v-if=" trackingType == 'own' ">

            <v-observer tag="form" ref="formShippingInformation" @submit.prevent="fnApiUpdateShippingInformation()">
              <div class="row">
                <div class="col-12 col-lg-6 ">
                  <v-validation :rules=" carriers.length > 0 ? 'required' : ''" v-slot="{ errors }"
                    :name=" $t('general.form.carrier') ">
                    <div class="form-group mb-1 ">
                      <label for="" v-text="  $t('general.form.carrier') "> </label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                          v-model="temptrackingNumber.carrier">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option :value="carrier.name" v-for=" (carrier,index) in  carriers " :key="index"
                            v-text=" carrier.name.toUpperCase() ">
                          </option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text"> <i class="fa fa-shipping-fast "></i> </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                </div>

                <div class="col-12 col-lg-6 ">
                  <v-validation rules="required|min:3|max:30" v-slot="{ errors }"
                    :name=" $t('general.form.trackingNumber') ">
                    <div class="form-group mb-1">
                      <label for="" v-text="$t('general.form.trackingNumber')"> </label>
                      <div class="input-group ">
                        <input type="text" class="form-control" v-model="temptrackingNumber.tracking_number"
                          placeholder="AR1234567890" :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="input-group-append">
                          <span class="input-group-text rounded-right"> <strong>
                              <i class=" fa fa-keyboard "></i>
                            </strong> </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                      <!-- <input type="text" v-model=" temptrackingNumber.tracking_number " placeholder="AR1234567890"
                        class="form-control" :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div> -->
                    </div>
                  </v-validation>
                </div>

              </div>
              <hr>
              <div class="card bg-warning">
                <div class="card-body">
                  <p>
                    <strong>
                      <i class="fa fa-exclamation-triangle  mr-1 "></i>
                      <span v-text="$t('dashboard.order.shipping.warning')"></span>
                    </strong>
                    <br>
                    <span v-text="$t('dashboard.order.shipping.ownShipmentWarningMessage')">
                    </span>
                  </p>
                </div>
              </div>
              <input type="submit" hidden>
            </v-observer>

          </div>

          <div v-if=" trackingType == 1">
            <div v-if=" calculateShipping == 0 ">
              <div class="row">
                <div class="col">
                  <h6 class="text-uppercase font-weight-bold text-primary " v-text=" ' package dimensions ' "></h6>
                </div>
              </div>
              <v-observer tag="form" ref="formShippingNewtracking" @submit.prevent="fnApiCreateShippingRates()">
                <div class="row animated fadeIn " v-show=" !productShipping ">
                  <div class="col-6 col-sm-6">
                    <div class="form-group">
                      <label for="" v-text=" 'Width' "></label>
                      <div class="input-group mb-3">
                        <input type="text" required class="form-control" v-model=" tempDimensions.width ">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6">
                    <div class="form-group">
                      <label for="" v-text=" 'Height' "></label>
                      <div class="input-group mb-3">
                        <input type="text" required class="form-control" v-model=" tempDimensions.height ">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6">
                    <div class="form-group">
                      <label for="" v-text=" 'Depth' "></label>
                      <div class="input-group mb-3">
                        <input type="text" required class="form-control" v-model=" tempDimensions.length ">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6">
                    <div class="form-group">
                      <label for="" v-text=" 'Weight' "></label>
                      <div class="input-group mb-3">
                        <input type="text" required class="form-control" v-model=" tempDimensions.weight ">
                        <div class="input-group-append">
                          <span class="input-group-text"> <strong v-text=" CONST.UNITS.WEIGHT "> </strong> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between ">
                  <div class="col-auto"> <button class=" btn  " v-text=" 'Back' "
                      @click=" trackingType = 0 ; calculateShipping = 0 "></button> </div>
                  <div class="col-auto"> <button class=" btn btn-info " v-text=" 'Calculate' "></button> </div>
                </div>
              </v-observer>

            </div>
            <div class=" p-3 border-success border animated fadeIn " v-show=" calculateShipping == 1  ">
              <h6> New shipping price:</h6>
              <div class="row">
                <div class="col-12" v-for=" (rate,index) in tempRates " :key=" index ">

                  <div class=" border p-2  ">
                    <div class="row text-uppercase ">
                      <div class="col-6">
                        <p> <span v-text=" 'Carrier: ' "> </span> <strong v-text="  rate.carrier "></strong></p>
                      </div>
                      <div class="col-6">
                        <p> <span v-text=" 'price: ' "> </span> <strong class=" text-success "
                            v-text=" '$' + rate.price + rate.currency "></strong>
                        </p>
                      </div>
                      <div class="col-6">
                        <p> <span v-text=" 'service: ' "> </span> <strong v-text=" rate.service "></strong> </p>
                      </div>
                      <div class="col-6">
                        <button class=" btn  btn-success " v-text=" 'Generate' "
                          @click="  fnApiNewtrackingNumber( rate.carrier , rate.service ) ">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class=" btn mb-2 " @click=" calculateShipping = 0 ; fnClearDimensions() " v-text=" 'Back' ">
              </button>
            </div>
          </div>

        </div>

        <div class="row" v-if=" trackingType == 'byAddress' && !internationalInformation ">

          <div class="col-12 col-xl-6">
            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h6>
                  <span v-text="$t('dashboard.order.title')"> </span>
                </h6>
                <button class="btn btn-sm btn-info" @click="fnGetOrderSummary()">
                  <i class="fa fa-print mr-1"></i>
                  <span v-text=" $t('general.button.orderSummary') "></span>
                </button>

              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="row justify-content-between">
                      <div class="col-auto">
                        <p>
                          <span v-text=" $t('general.form.number') + ': ' "></span>
                        </p>
                      </div>
                      <div class="col-auto">
                        <p>
                          <span v-text=" orderInformation.number "> </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row justify-content-between">
                      <div class="col-auto">
                        <p>
                          <span v-text=" $t('general.form.store') + ': ' "></span>
                        </p>
                      </div>
                      <div class="col-auto">
                        <p class="text-truncate">
                          <span v-text="  storeInformation.name "> </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>

                <!-- PRODUCTS -->

                <div>
                  <table class=" table table-sm table-bordernone table-striped">
                    <tbody>
                      <tr v-for=" (item, index) in  orderInformation.items " :key="index"
                        class=" text-center border-bottom-primary ">
                        <td class=" ">
                          <div class="row">
                            <div class="col-auto">
                              <img v-image=" item.image_url " class=" img-cover-40 " alt="">
                            </div>
                            <div class="col text-left ">
                              <p v-if="item.sku" class="mb-0 text-truncate text-uppercase">
                                <small class="text-muted " v-text=" $t('tables.sku') +': ' + item.sku ">
                                </small>
                              </p>
                              <p class="m-0 text-truncate "> <span v-text=" fnCutText( item.name, 20 ) ">
                                </span> </p>
                              <p v-if="  item.variant_name && item.variant_name != 'Default Title' "> <span
                                  v-text=" $t('dashboard.products.variant')  +': ' + item.variant_name ">
                                </span> </p>

                              <p class="text-right"
                                v-text=" item.quantity + ' X ' + fnFormatPrice(  item.price , orderInformation.currency )  + ' = ' + fnFormatPrice( item.total , orderInformation.currency )">
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                  <div class="row " v-if="orderInformation && orderInformation.totals">
                    <div class="col-6">
                      <p> Subtotal: </p>
                    </div>
                    <div class="col-6 text-right ">
                      <span
                        v-text=" fnFormatPrice( orderInformation.totals.subtotal , orderInformation.currency ) "></span>
                    </div>
                    <div class="col-6">
                      <p v-text=" $t('general.form.discount') + ': '"> </p>
                    </div>
                    <div class="col-6 text-right">
                      <span
                        v-text=" fnFormatPrice( orderInformation.totals.discount , orderInformation.currency ) "></span>
                    </div>
                    <div class="col-6">
                      <p v-text=" $t('general.form.tax') + ': ' "> </p>
                    </div>
                    <div class="col-6 text-right ">
                      <span v-text=" fnFormatPrice( orderInformation.totals.tax , orderInformation.currency ) "></span>
                    </div>
                    <div class="col-6 ">
                      <p v-text=" $t('general.form.shipping') + ': ' "></p>
                    </div>
                    <div class="col-6 text-right">
                      <span v-text=" fnFormatPrice( orderInformation.totals.shipping , orderInformation.currency )  "
                        v-if=" orderInformation.totals.shipping > 0"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <div class="col-6">
                    <p class="m-0" v-text=" $t('general.form.total') + ': ' "> </p>
                  </div>
                  <div class="col-6 text-right">
                    <p class="m-0" v-if="orderInformation && orderInformation.totals"
                      v-text=" fnFormatPrice( orderInformation.totals.total , orderInformation.currency ) ">

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-6 ">

            <div class="card">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h6>
                  <span v-text="$t('dashboard.order.packageInformation')"> </span>
                  <small class="text-warning" v-text=" ' (' + $t('dashboard.order.shipping.warning') +')'"
                    v-if=" ecarttrackingNumber.own_shipping "></small>
                </h6>
                <a class="btn btn-sm btn-info " :href="ecarttrackingNumber.label" target="_blank"
                  v-if=" ecarttrackingNumber.label ">
                  <i class=" fa fa-print mr-1 "> </i>
                  <span v-text=" $t('general.button.printLabel') "></span>
                </a>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col-auto mx-auto">
                    <img v-image=" fnGetCarrierImage(ecarttrackingNumber.carrier) " class="img-100 my-4" alt="">
                  </div>
                </div>
                <!-- <pre> {{ ecarttrackingNumber }} </pre> -->
                <div class="row">
                  <div class="col-12 col-lg-6" v-if="ecarttrackingNumber.tracking_number">
                    <div class="row justify-content-between ">
                      <div class="col-auto">
                        <p>
                          <span v-text=" $t('general.form.status') + ': ' "></span>
                        </p>
                      </div>
                      <div class="col-auto">
                        <p>
                          <strong v-text=" $t('general.readyToShip') " class="text-info"
                            v-if="orderInformation.shipment_status == 'ready to ship' "> </strong>
                          <strong v-text=" $t('general.delivered') " class="text-success"
                            v-if="orderInformation.shipment_status == 'delivered' "> </strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6" v-if="ecarttrackingNumber.tracking_number">
                    <div class="row justify-content-between">
                      <div class="col-auto">
                        <p v-text=" $t('general.form.label') + ': ' "></p>
                      </div>
                      <div class="col-auto">
                        <p class=" ">
                          <strong v-text=" ecarttrackingNumber.tracking_number ">
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="row justify-content-between ">
                      <div class="col-auto">
                        <p>
                          <span v-text=" $t('general.form.carrier') + ': ' "></span>
                        </p>
                      </div>
                      <div class="col-auto">
                        <p>
                          <span class="text-capitalize" v-text=" ecarttrackingNumber.carrier "> </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="row justify-content-between">
                      <div class="col-auto">
                        <p>
                          <span v-text=" $t('tables.service') + ': ' "></span>
                        </p>
                      </div>
                      <div class="col-auto">
                        <p>
                          <span class="text-capitalize" v-text=" ecarttrackingNumber.service "> </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="">
                      <div class="row">
                        <div class="col-12">
                          <div class="row justify-content-between">
                            <div class="col-auto col-sm-7">
                              <p class="m-0"> <span v-text=" $t('tables.type')+ ':' "></span>
                              </p>
                            </div>
                            <div class="col-auto col sm-5">
                              <p class="m-0 text-uppercase" v-if="ecarttrackingNumber.packages[0]">
                                <span v-text=" ecarttrackingNumber.packages[0].type "></span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row justify-content-between">
                            <div class="col-auto col-sm-7">
                              <p class="m-0"> <span v-text=" $t('tables.amount') + ':' "></span>
                              </p>
                            </div>
                            <div class="col-auto col sm-5" v-if="ecarttrackingNumber.packages[0]">
                              <p class="m-0"> <span v-text=" ecarttrackingNumber.packages[0].amount "></span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 ">
                          <div class="row justify-content-between">
                            <div class="col-auto col-sm-7">
                              <p class="m-0"> <span v-text=" $t('tables.weight') + ':' "></span>
                              </p>
                            </div>
                            <div class="col-auto col sm-5">
                              <p class="m-0" v-if="ecarttrackingNumber.packages[0]">
                                <span
                                  v-text=" ecarttrackingNumber.packages[0].weight + ' ' +  ecarttrackingNumber.packages[0].weightUnit "></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6">
                    <div class="">
                      <div class="row justify-content-between">
                        <div class="col-auto">
                          <p class="m-0"> <span v-text=" $t('tables.length')+ ':' "></span> </p>
                        </div>
                        <div class="col-auto">
                          <p class="m-0" v-if="ecarttrackingNumber.packages[0]">
                            <span
                              v-text=" ecarttrackingNumber.packages[0].dimensions.length + ' ' + ecarttrackingNumber.packages[0].lengthUnit "></span>
                          </p>
                        </div>
                      </div>
                      <div class="row justify-content-between">
                        <div class="col-auto">
                          <p class="m-0"> <span v-text="  $t('tables.width')+ ':' "></span> </p>
                        </div>
                        <div class="col-auto">
                          <p class="m-0" v-if="ecarttrackingNumber.packages[0]">
                            <span
                              v-text=" ecarttrackingNumber.packages[0].dimensions.width + ' ' + ecarttrackingNumber.packages[0].lengthUnit "></span>
                          </p>
                        </div>
                      </div>
                      <div class="row justify-content-between">
                        <div class="col-auto">
                          <p class="m-0"> <span v-text="  $t('tables.height')+ ':' "></span> </p>
                        </div>
                        <div class="col-auto">
                          <p class="m-0" v-if="ecarttrackingNumber.packages[0]">
                            <span
                              v-text=" ecarttrackingNumber.packages[0].dimensions.height + ' ' + ecarttrackingNumber.packages[0].lengthUnit "></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer "
                v-if=" ecarttrackingNumber.own_shipping || (ecarttrackingNumber.tracking_number == null) ">
                <p class="mb-0" v-if=" ecarttrackingNumber.own_shipping ">
                  <span v-text="$t('dashboard.order.shipping.ownShipmentMessage')"> </span>
                </p>

                <p class="m-0" v-if="ecarttrackingNumber.tracking_number == null">
                  <span v-text=" $t('components.trackingNumber.messageTracking') + ' ' ">
                  </span>
                  <button class=" btn btn-link p-0 " v-text=" $t('linkMessages.here') + '.' "
                    @click=" trackingType = 'own' ">
                  </button>
                </p>

              </div>
            </div>

            <OrderBol v-bind:orderInformation="orderInformation" @update-bol="fnUpdateBOL"
              v-if="orderInformation.international && ecarttrackingNumber.tracking_number " />

          </div>
        </div>

      </div>
      <div class=" text-center text-success text-uppercase py-3 " v-else>
        <i class=" fa fa-4x fa-circle-notch fa-spin my-4 "></i>
        <h5 v-text=" $t('general.generating') + '...' "></h5>
      </div>


    </div>

    <template v-slot:modal-footer v-show=" ecarttrackingNumber.base_amount ">
      <div class="w-100 " v-if=" !ecarttrackingNumber.own_shipping && !ecarttrackingNumber.tracking_number ">
        <div class="d-flex justify-content-between w-100 " v-if=" trackingType == 'byAddress' ">
          <b-button variant="primary" class=" mr-2" @click=" trackingStep = 0 " v-text=" $t('general.button.back') "
            v-if=" trackingStep == 1 ">
          </b-button>
          <div class="ml-auto">
            <b-button variant="none" class=" mr-2" @click=" modaltracking = !modaltracking "
              v-text=" $t('general.button.close') ">
            </b-button>
            <b-button variant="success" class=" mr-2" :disabled="sendingData" @click=" fnValidateAddress() ">
              <span v-text=" $t('general.button.createLabel') " v-if=" !sendingData "></span>
              <i class="fa fa-spinner fa-spin " v-else></i>
            </b-button>
          </div>
        </div>
        <div class="d-flex justify-content-between w-100 " v-else>
          <b-button variant="primary" class=" mr-2" @click=" trackingType = 'byAddress' "
            v-text=" $t('general.button.back') ">
          </b-button>
          <div class="ml-auto">
            <b-button variant="none" class=" mr-2" @click=" modaltracking = !modaltracking "
              v-text=" $t('general.button.close')">
            </b-button>
            <b-button variant="success" class=" mr-2" :disabled="sendingData"
              @click=" fnApiUpdateShippingInformation() ">
              <span v-text=" $t('general.button.update') " v-if=" !sendingData "></span>
              <i class="fa fa-spinner fa-spin " v-else></i>
            </b-button>
          </div>
        </div>
      </div>
      <div v-else>
        <b-button variant="none" class=" mr-2" @click=" modaltracking = !modaltracking "
          v-text=" $t('general.button.close')">
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import OrderShimpentDetails from "./order_shipment_details";
  import OrderBol from "./order_bol";
  import OrderInternational from "./order_international_information";
  import AddressForm from "@/components/AddressForm";
  import printJS from 'print-js';

  export default {
    name: "OrderShipmentModal",
    props: {
      showtrackingNumber: {
        type: Boolean,
        default: false,
      },
      orderInformation: {
        type: Object,
        required: true,
      },
      orderPage: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      OrderShimpentDetails,
      OrderInternational,
      OrderBol,
      AddressForm,
    },
    watch: {
      showtrackingNumber() {
        if (this.orderInformation.status == 'paid') {
          this.modaltracking = true;
        }
        this.activeTab = "details";
        this.fnDefaultAddress();
      },
      orderInformation() {
        this.internationalInformation = false;
        this.tempOrderInformation = this.orderInformation;
        this.ecarttrackingNumber = this.orderInformation.shipping_items[0];
        this.temptrackingNumber.id = this.orderInformation.shipping_items[0].id;
        this.fnApiGetStoreAddresses();
      },
      addressesList() {
        this.fnDefaultAddress();
      },
    },
    computed: {
      originAddress() {
        if (this.orderInformation.shipping_items.length > 0) {
          return this.orderInformation.shipping_items[0].origin;
        }
        return {};
      }
    },

    data() {
      return {
        activeTab: "details",
        tempOrderInformation: {},
        storeInformation: {},
        newAddresses: {
          origin: false,
          shippingAddress: false,
        },
        addressesList: [],
        carriers: [],
        trackingStep: 0,
        ecarttrackingNumber: {
          packages: []
        },
        tempAddressId: null,
        moreInfo: false,
        internationalInformation: false,
        modaltracking: false,
        productShipping: false,
        tempDimensions: {
          width: null,
          height: null,
          length: null,
          weight: null,
        },
        tempRates: [],
        trackingType: 'byAddress',
        calculateShipping: 0,
        creatingTrackingNumber: false,
        sendingData: false,
        temptrackingNumber: {
          carrier: null,
          tracking_number: null,
          tracking_url: null,
        },
        shippingInformation: {
          carrier: null,
          tracking_number: null,
          tracking_url: null,
        },
        disabledFields: {
          origin: ['addressName'],
          shippingAddress: ['addressName', 'userName', 'userLastName', 'phone'],
        }
      }
    },
    methods: {

      fnGetCarrierImage(carrierName) {
        let tempCarrier = this.carriers.find(carrier => carrier.name == carrierName);
        if (tempCarrier) {
          return tempCarrier.image;
        } else {
          return null;
        }
      },

      fnDefaultAddress() {
        this.addressesList.map(address => {
          if (address.default) {
            this.tempAddressId = address.id;
            this.fnChangeAddress();
          }
        });
      },
      fnChangeAddress() {
        this.tempAddress = {};
        this.newAddresses.origin = false;
        if (this.tempAddressId) {
          this.tempAddress = this.addressesList.find(address => address.id == this.tempAddressId);
        }
      },
      fnResetShippingInfo() {
        this.modaltracking = false;
        this.tempAddressId = null;
        this.trackingType = 'byAddress';
        this.calculateShipping = 0;
        this.temptrackingNumber = {
          carrier: null,
          tracking_number: null,
          tracking_url: null,
        }
        this.newAddresses = {
            origin: false,
            shippingAddress: false,
          },
          this.fnClearDimensions();
      },

      fnClearDimensions() {
        this.tempDimensions = {
          width: null,
          height: null,
          length: null,
          weight: null,
        };
      },
      async fnValidateAddress() {
        if (await this.$refs['formAddress'].validate()) {
          let index = this.$lodash.findIndex(this.addressesList, ['id', this.tempAddressId]);
          if (this.addressesList[index].country.code == this.orderInformation.shipping_address.country.code) {
            this.fnApiUpdateAddresses();
          } else {
            this.internationalInformation = true;
          }
        }
      },
      async fnApiUpdateAddresses() {
        this.sendingData = true;
        let tempAddresses = {};
        if (this.newAddresses.origin) {
          let originAddress = await this.$refs.originAddress.fnValidateData();
          if (originAddress == null) {
            this.internationalInformation = false;
            this.sendingData = false;
            return null;
          }
          originAddress = this.fnGlobalGetAddress(originAddress);
          delete originAddress.address_name;
          tempAddresses.origin_address = originAddress;
        }

        if (this.newAddresses.shippingAddress) {
          let shippingAddress = await this.$refs.shippingAddress.fnValidateData();
          if (shippingAddress == null) {
            this.internationalInformation = false;
            this.sendingData = false;
            return null;
          }
          shippingAddress = this.fnGlobalGetAddress(shippingAddress);
          delete shippingAddress.address_name;
          tempAddresses.shipping_address = shippingAddress;
        }
        this.fnApiCreateShippingLabel(tempAddresses);
      },

      async fnApiCreateShippingLabel(tempAddresses) {
        this.creatingTrackingNumber = true;
        let {
          carrier,
          service
        } = this.ecarttrackingNumber;

        let tempData = {
          carrier,
          service,
          origin_id: this.tempAddressId,
        }
        if (tempAddresses.origin_address) {
          tempData.origin_address = tempAddresses.origin_address;
        }
        if (tempAddresses.shipping_address) {
          tempData.shipping_address = tempAddresses.shipping_address;
        }
        this.sendingData = true;
        await axios.post(`orders/${this.orderInformation.id}/shippings/generate`, tempData).then((response) => {
          this.$toasted.global.successMessage(this.$t('toasted.success.trackingNumberCreate'));

          response.data.origin = this.tempAddress;
          this.ecarttrackingNumber.tracking_number = response.data.tracking_number;
          this.ecarttrackingNumber.tracking_url = response.data.tracking_url;
          this.ecarttrackingNumber.own_shipping = response.data.own_shipping;
          this.ecarttrackingNumber.label = response.data.label;
          if (response.data.bill_of_landing) {
            this.ecarttrackingNumber.bill_of_landing = response.data.bill_of_landing;
            // this.activeTab = "bol"
          }
          this.$emit('update-order', response.data);
        }).catch((error) => {});
        this.sendingData = false;
        this.internationalInformation = false;
        setTimeout(() => {
          this.creatingTrackingNumber = false;
        }, 1000);
      },

      fnApiGetOrderRates(orderId) {
        axios.get(`orders/${orderId}/shippings`).then((response) => {
          this.shippingInformation.rates = response.data;
        }).catch((error) => {});
      },

      // ShippingRates
      async fnApiCreateShippingRates() {
        if (await this.$refs['formShippingNewtracking'].validate()) {
          axios.post(`orders/${this.orderInformation.id}/shippings/rates`, this.tempDimensions).then((
            response) => {
            this.calculateShipping = 1;
            this.tempRates = response.data;
          }).catch((error) => {});
        }
      },

      fnApiNewtrackingNumber(carrier, service) {
        this.tempDimensions.carrier = carrier;
        this.tempDimensions.service = service;
        axios.post(`orders/${this.orderInformation.id}/shippings/generate`, this.tempDimensions).then((
          response) => {
          this.$toasted.global.successMessage(this.$t('toasted.success.trackingNumberCreate'));
          this.fnResetShippingInfo();
        }).catch((error) => {});
      },

      async fnApiUpdateShippingInformation() {
        if (await this.$refs['formShippingInformation'].validate()) {
          this.sendingData = true;
          this.creatingTrackingNumber = true;
          let {
            id,
            carrier,
            tracking_number
          } = this.temptrackingNumber;
          await axios.put(`orders/${this.orderInformation.id}/shippings/${id}`, {
            carrier,
            tracking_number
          }).then((response) => {
            this.ecarttrackingNumber.tracking_number = response.data.tracking_number;
            this.ecarttrackingNumber.own_shipping = response.data.own_shipping;
            this.$toasted.global.successMessage(this.$t('toasted.success.trackingNumberCreate'));
            this.$emit('update-order', response.data);
          }).catch(error => {});
          this.sendingData = false;
          setTimeout(() => {
            this.creatingTrackingNumber = false;
          }, 1000);
        }
      },
      fnApiGetStoreAddresses() {
        axios.get(`stores/${this.tempOrderInformation.store_id}`).then((response) => {
          this.storeInformation = response.data;
          this.addressesList = response.data.addresses;
          this.trackingStep = 0;
        }).catch((error) => {});
      },
      fnSetAddress(index) {
        for (let tempIndex in this.addressesList) {
          if (tempIndex == index) {
            this.addressesList[tempIndex].default = true;
          } else {
            this.addressesList[tempIndex].default = false;
          }

        }
      },
      fnApiCreateBill() {
        axios.post(`orders/${this.tempOrderInformation.id}/shippings/billoflading`).then((response) => {}).catch((
          error) => {});
      },
      fnApiGetCarriers() {
        axios.get(`carriers`).then((response) => {
          this.carriers = response.data;
        }).catch((error) => {});
      },
      fnUpdateBOL(shiping) {
        this.ecarttrackingNumber.bill_of_landing = shiping.bill_of_landing;
      },
      fnGetOrderSummary() {
        axios.get(`orders/summary/${this.orderInformation.id}`, {
          headers: this.headers,
          responseType: 'blob',
        }).then((response) => {
          var pdfFile = new Blob([response.data], {
            type: "application/pdf"
          });
          var pdfUrl = URL.createObjectURL(pdfFile);
          printJS(pdfUrl);
        }).catch(error => {});
      }

    },
    mounted() {
      this.fnApiGetCarriers();
    }
  }
</script>

<style>
  .order-address-header {
    min-height: 130px;
  }

  .order-address-body {
    min-height: 130px;
  }
</style>