<template>
  <div class=" h-100-vh form-rounded-inputs">
    <pull-refresh ref="scroller" :on-refresh="fnRefresh">

      <div class="d-none d-md-block">
        <a class="banner-ecart d-flex justify-content-center card"
          href="https://envia.com/es-MX/servicios-de-almacenamiento-fulfillment" target="_blank"
          v-if="this.$i18n.locale == 'es'">
          <img src="@/assets/images/banner/orders/es/600x100.png" alt=""
            class="banner-image card  m-0 border-0 d-sm-block d-xl-none ">
          <img src="@/assets/images/banner/orders/es/1400x100.png" alt=""
            class="banner-image card  m-0 border-0 d-none d-xl-block">
        </a>
        <a class="banner-ecart d-flex justify-content-center card" href="https://envia.com/en-US/fulfillment-solution"
          target="_blank" v-else>
          <img src="@/assets/images/banner/orders/en/600x100.png" alt=""
            class="banner-image card  m-0 border-0 d-sm-block d-xl-none ">
          <img src="@/assets/images/banner/orders/en/1400x100.png" alt=""
            class="banner-image card  m-0 border-0 d-none d-xl-block">
        </a>
      </div>

      <h5 class="text-center">
        <small v-if="pagination.archived != null ">
          <strong class="text-info" v-text=" $t('general.button.archived') " v-if="pagination.archived === true">
          </strong>
          <strong class="text-info" v-text=" $t('general.selectOptions.archived.unfiled') "
            v-if="pagination.archived === false"> </strong>
        </small>
      </h5>

      <div class="">
        <v-observer tag="form" ref="formPagination" class="" @submit.prevent="fnApiGetOrders()">
          <div class="row justify-content-end align-items-end">
            <div class="col-12 col-lg-6 col-xl-2 ml-auto ">
              <div class="form-group animated fadeIn">
                <div class="row align-items-end ">
                  <div class="col">
                    <label for="">
                      <span v-text=" $t('dashboard.orders.number') "></span>
                    </label>
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="OR12345678" v-model="pagination.number "
                        @change="fnApiGetOrders( ); fnApiGetCountOrders();">
                      <div class="input-group-append">
                        <button class="btn btn-success"> <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto d-md-none">
                    <div class="row">
                      <div class="col-auto pl-0">
                        <button type="button" @click="showAdvancedFilters =! showAdvancedFilters" v-if="mobileVersion"
                          class="btn btn-info circle-35">
                          <transition name="custom-classes-transition" mode="out-in"
                            enter-active-class="animated jello">
                            <i class=" fa fa-sliders-h" v-if="!showAdvancedFilters" key="open"></i>
                            <i class=" fa fa-times" v-if="showAdvancedFilters" key="close"></i>
                          </transition>
                        </button>
                      </div>
                      <div class="col-auto pl-0 d-none">
                        <button class="btn btn-success circle-35 p-0" type="button"
                          @click="changeModal( {newCart: true} )" :disabled="loading.newCart">
                          <i class="fa fa-plus-circle fa-lg" v-if="!loading.newCart"></i>
                          <i class="fa fa-spinner fa-spin" v-else></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg-6 col-xl-2 animated fadeIn  " v-show="showAdvancedFilters">
              <div class="form-group ">
                <label for="" v-text="$t('dashboard.products.store')"></label>
                <div class="input-group">
                  <select class="custom-select" v-model="pagination.store_id " @change="fnResetPagination() ">
                    <option :value="null" v-text="$t('general.form.all')"> </option>
                    <option :value="store.id" v-for=" (store,index) in storeList " :key="index"
                      v-text=" store.name ? store.name : fnStoreURL(store.url) ">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <button class="btn btn-success"> <i class="fa fa-store"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-6 col-md-6  col-lg-3 col-xl-2 animated fadeIn" v-show="showAdvancedFilters">
              <div class="form-group ">
                <label for="" v-text="$t('dashboard.orders.country')"></label>
                <div class="input-group">
                  <select class="custom-select" v-model="pagination.country "
                    @change=" fnApiGetOrders(); fnApiGetCountOrders(); ">
                    <option :value="null" v-text="$t('general.form.all')"> </option>
                    <option :value="country.code" v-for=" (country,index) in countries " :key="index"
                      v-text=" country.name ? country.name : country.code"> </option>
                  </select>
                  <div class="input-group-append">
                    <button class="btn btn-success">
                      <i class="fa fa-flag"></i>
                    </button>

                  </div>
                </div>
              </div>

            </div>

            <div class="col-6 col-md-6 col-lg-3 col-xl-2  animated fadeIn" v-show="showAdvancedFilters">
              <div class="form-group">
                <label for="" v-text="$t('dashboard.orders.status')"></label>
                <div class="input-group">
                  <select class="custom-select" v-model="pagination.status"
                    @change="fnApiGetOrders(); fnApiGetCountOrders() ">
                    <option :value="null" v-text="$t('general.form.all')"> </option>
                    <option value="shipment_pending" v-text="$t('general.form.ordersFilter.shippingPending')">
                    </option>
                    <option value="payment_pending" v-text="$t('general.form.ordersFilter.paymentPending')">
                    </option>
                    <option value="cancelled" v-text="$t('general.form.ordersFilter.cancelled')"> </option>
                  </select>
                  <div class="input-group-append">
                    <button class="btn btn-success"> <i class="fa fa-ellipsis-h"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg-3 col-xl-2  animated fadeIn" v-show="showAdvancedFilters">
              <div class="form-group">
                <label for="" v-text="$t('dashboard.order.type') "></label>
                <div class="input-group">
                  <select class="custom-select" v-model="pagination.archived"
                    @change="fnApiGetOrders(); fnApiGetCountOrders() ">
                    <option :value="null" v-text="$t('general.form.all')"> </option>
                    <option :value="false" v-text="$t('general.selectOptions.archived.unfiled')"> </option>
                    <option :value="true" v-text="$t('general.selectOptions.archived.archived')"> </option>
                  </select>
                  <div class="input-group-append">
                    <button class="btn btn-success"> <i class="fa fa-ellipsis-h"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-lg-2 col-xl-1 d-none ">
              <div class="form-group ">
                <label for=""> <i class="fa fa-arrows-v"></i> Limit </label>
                <select class=" form-control " v-model="pagination.limit " @change="fnApiGetOrders();">
                  <option :value="limit" v-for=" (limit,index) in CONST.PAGINATION " :key="index" v-text=" limit ">
                  </option>
                </select>
              </div>
            </div>

            <div class="col-6 col-md-12 col-lg-3 col-xl-2 d-none d-md-block1 animated fadeIn">
              <div class="form-group">
                <button class="btn btn-success w-100 " type="button" @click="changeModal( {newCart: true} )"
                  :disabled="loading.newCart">
                  <span v-text="$t('general.new')" v-if="!loading.newCart"></span>
                  <i class="fa fa-spinner fa-spin" v-else></i></button>
              </div>
            </div>
          </div>
        </v-observer>
        <no-data :dataMessage="$t('noDataMessages.ordersList')" :isLoading="loading.data" :showBtn="false"
          v-if="loading.data || orderList.length == 0">
        </no-data>
        <div v-else>

          <!-- <pre> {{ draftList[0]  }} </pre> -->
          <!-- Mobile -->
          <table class="table ecart-table w-100 d-table d-lg-none table-striped ">
            <thead>
              <tr>
                <th v-text=" $t('dashboard.orders.order') "> </th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr class="">
                <td>
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p class="">
                        <strong>
                          <router-link class="text-info" :to="'/dashboard/orders/' "
                            v-text=" '#' + $t('general.draft') ">
                          </router-link>
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class=" w-100 ">
                    <button class="btn btn-outline-info w-100" v-text="$t('general.draft')">
                    </button>
                  </div>
                </td>
              </tr>

              <tr class="">
                <td>
                  <div class="row align-items-center">
                    <div class="col">
                      <p class=" m-0 ">
                        <strong>
                          <router-link :to="'/dashboard/orders/'" v-text=" '#123456789' ">
                          </router-link>
                        </strong>
                      </p>
                    </div>
                    <div class="col-auto">
                      <button class="btn btn-success circle-30 mx-auto">
                        <i class="fa fa-ellipsis-h fa-lg "></i>
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 ">
                      <p class="f-w-500">
                        <span v-text="'MX'"> </span>
                        <i class="flag-icon mx-1 f-12" :class=" 'flag-icon-mx' "></i>
                      </p>
                    </div>
                    <div class="col-6 ">
                      <p class=" f-w-500 text-right text-muted ">
                        <span v-text=" '20-08-2021' "> </span>
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="m-0 f-w-600">
                        <span v-text=" 'ricardoaaron9@gmail.com' ">
                        </span>
                      </p>
                    </div>
                    <div class="col-auto ml-auto ">
                      <p class="text-right">
                        <span v-text=" '$1,000.00MXN' "> </span>
                      </p>
                    </div>
                  </div>
                  <div class=" w-100 ">
                    <button class="btn btn-success w-100" @click="showSuborders = !showSuborders">
                      <i class="fa fa-caret-up" v-if="showSuborders"></i>
                      <i class="fa fa-caret-down" v-else></i>
                      <span class="ml-2" v-text="'Suborders'"></span>
                    </button>
                  </div>
                </td>
              </tr>

              <div class="table-tr bg-dark border-bottom border-2 animated fadeIn" v-show="showSuborders">
                <td>
                  <div class="row">
                    <div class="col-6">
                      <p>
                        <strong>
                          <router-link :to="'/dashboard/orders/' + '112345' " v-text=" '#SUOR12345678' ">
                          </router-link>
                        </strong>
                      </p>
                    </div>
                    <div class="col-6 text-right">
                      <p> $3,896.02MXN</p>
                    </div>
                  </div>
                </td>
              </div>
              <div class="table-tr bg-dark border-bottom border-2 animated fadeIn" v-show="showSuborders">
                <td>
                  <div class="row">
                    <div class="col-6">
                      <p>
                        <strong>
                          <router-link :to="'/dashboard/orders/' + '112345' " v-text=" '#SUOR12345678' ">
                          </router-link>
                        </strong>
                      </p>
                    </div>
                    <div class="col-6 text-right">
                      <p> $2,000.02MXN</p>
                    </div>
                  </div>
                </td>
              </div>
              <div class="table-tr bg-dark border-bottom border-2 animated fadeIn" v-show="showSuborders">
                <td>
                  <div class="row">
                    <div class="col-6">
                      <p>
                        <strong>
                          <router-link :to="'/dashboard/orders/' + '112345' " v-text=" '#SUOR12345678' ">
                          </router-link>
                        </strong>
                      </p>
                    </div>
                    <div class="col-6 text-right">
                      <p> $1,500.02MXN</p>
                    </div>
                  </div>
                </td>
              </div> -->

              <tr v-for=" (order, index ) in orderList  " :key=" index " class="animated"
                :class="(loading.order.id == order.id && loading.order.remove) ? 'fadeOut':'' ">
                <td>
                  <div class="row align-items-center">
                    <div class="col">

                      <div v-if="order.is_from_seller">
                        <div v-if="order.status == 'pending' ">
                          <p>
                            <strong>
                              <router-link class="text-uppercase text-info"
                                :to="'/dashboard/new-cart/' + order.cart_id "
                                v-text=" '#' + order.number + '-' + order.store_id.slice(-3) ">
                              </router-link>
                            </strong>
                            <br>
                          </p>
                          <p v-if="order.email">
                            <small class="text-muted f-w-500" v-text=" $t('general.email') + ': ' + (order.email) ">
                            </small>
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <p class=" m-0 ">
                          <strong>
                            <router-link class="text-uppercase" :to="'/dashboard/orders/' + order.id "
                              v-text=" '#' + order.number + '-' + order.store_id.slice(-3) ">
                            </router-link>
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div class="col-auto">
                      <b-dropdown variant="success circle-30" right no-caret :disabled="loading.order.id == order.id">

                        <template v-slot:button-content class="m-0">
                          <i class="fa fa-ellipsis-h fa-lg"></i>
                        </template>

                        <b-dropdown-item :to="'/dashboard/orders/' + order.id ">
                          <p>
                            <i class="fa fa-external-link-alt mr-1"></i>
                            <span v-text=" $t('general.details') "></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if=" (order.order_status != 'cancelled' && order.order_status != 'in_review' && order.status == 'paid' ) && (order.shipment_status == 'pending') "
                          @click="fnOpenOrderComponent( index )">
                          <p>
                            <i class="fa fa-receipt mr-1"></i>
                            <span v-text=" $t('general.createLabel') "></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-item @click="fnApiArchiveOrder(index)" v-if=" pagination.archived != null ">
                          <p>
                            <i class="fa mr-1" :class=" pagination.archived ? 'fa-folder-minus':'fa-folder-plus' "></i>
                            <span
                              v-text=" pagination.archived ? $t('general.selectOptions.archived.unfiled') : $t('tables.archive') "></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="fnGetOrderSummary(order.id)">
                          <p>
                            <i class="fa fa-print mr-1"></i>
                            <span v-text=" $t('general.button.orderSummary')"></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-item :href="order.shipping_items[0].label" target="_blank"
                          v-if=" order.shipping_items[0] && order.shipping_items[0].label ">
                          <p>
                            <i class="fa fa-print mr-1"></i>
                            <span v-text=" $t('general.button.printLabel')"></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-item @click="fnUpdateOrderBOL(order.shipping_items[0].bill_of_landing)"
                          v-if="order.shipping_items[0] && order.shipping_items[0].bill_of_landing">
                          <p>
                            <i class="fa fa-print mr-1"></i>
                            <span v-text=" $t('general.button.BOL')"></span>
                          </p>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6 ">
                      <p class="f-w-500" v-if="order.shipping_address && order.shipping_address.country.code">
                        <span v-text="order.shipping_address.country.code"> </span>
                        <i class="flag-icon mx-1 f-12"
                          :class=" 'flag-icon-' + (order.shipping_address.country.code).toLowerCase() "></i>
                      </p>
                    </div>
                    <div class="col-6 ">
                      <p class=" f-w-500 text-right text-muted ">
                        <span v-text="fnDate( order.created_at, CONST.DATE_FORMATS.DD_MM_YYYY )"> </span>
                      </p>
                    </div>
                    <div class="col-auto">
                      <p class="m-0 f-w-600" v-if="order.shipping_address">
                        <span v-text=" order.shipping_address.first_name + ' ' + order.shipping_address.last_name  ">
                        </span>
                      </p>
                    </div>
                    <div class="col-auto ml-auto ">
                      <p class="text-right">
                        <span v-text="fnFormatPrice( order.totals.total , order.currency )"> </span>
                      </p>
                    </div>
                  </div>

                  <div class=" w-100 " v-if=" !order.is_from_seller">
                    <div v-if=" order.order_status == 'cancelled'">
                      <button class="w-100 btn btn-light  " disabled>
                        <span class="text-danger " v-text="$t('general.cancelled') "></span>
                      </button>
                    </div>
                    <div v-else>
                      <div v-if=" order.status == 'paid' ">
                        <button class="btn-status w-100 btn btn-success " @click="fnOpenOrderComponent( index )"
                          v-if=" order.shipment_status == 'pending'  && order.order_status != 'in_review' ">
                          <i class="fa fa-receipt mr-1"></i> <span v-text="$t('general.createLabel') "></span>
                        </button>
                        <button class="btn-status w-100 btn btn-outline-success bg-white text-success btn-white"
                          @click="fnOpenOrderComponent( index )"
                          v-if=" order.shipment_status == 'ready to ship' && order.order_status != 'in_review' ">
                          <i class="fa fa-shipping-fast mr-1"></i>
                          <span v-text="$t('general.button.viewDetails')"></span>
                        </button>
                        <button class="btn-status w-100 btn btn-outline-success bg-white text-success btn-white"
                          @click="fnOpenOrderComponent( index )" v-if=" order.shipment_status == 'shipped' ">
                          <i class="fa fa-shipping-fast mr-1"></i>
                          <span v-text="$t('general.shipped')"></span>
                        </button>
                        <p v-if=" order.shipment_status == 'delivered' " class=" m-0 text-center text-success">
                          <i class="fa fa-check-circle mr-1"></i>
                          <span v-text="$t('general.delivered')"></span>
                        </p>
                        <p v-if=" order.shipment_status == 'information' " class=" m-0 text-info">
                          <span v-text=" $t('tables.status') + ' : ' "></span>
                          <span v-text="$t('general.information')"></span></p>
                        <p v-if=" order.shipment_status == 'cancelled' " class=" m-0 text-info">
                          <span v-text=" $t('tables.status') + ' : ' "></span>
                          <span v-text="$t('general.cancelled')"></span></p>
                        <!-- <p v-if=" order.shipment_status == 'N/A' " class=" m-0 text-info">
                            <span v-text=" $t('tables.status') + ' : ' "></span> N/A</p>
                            </p> -->
                        <p v-if=" order.shipment_status == 'picked up' " class=" m-0 text-info">
                          <span v-text="$t('general.pickedUp')"></span>
                        </p>
                        <p v-if=" order.shipment_status == 'out for delivery' " class=" m-0 text-info">
                          <span v-text="$t('general.outForDelivery')"></span>
                        </p>
                      </div>
                      <div v-else>
                        <button class="btn btn-light w-100" disabled>
                          <i class=" fa fa-hand-holding-usd mr-1 "> </i>
                          <span v-text=" $t('general.paymentPending') "></span>
                        </button>
                      </div>
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>

          <!-- Desktop -->
          <div class=" d-none d-lg-block w-100 ">
            <table class="table ecart-table table-striped ">
              <thead class=" text-center ">
                <tr class=" ">
                  <th class=" text-left " v-text=" $t('dashboard.orders.order') "> </th>
                  <th v-text=" $t('dashboard.orders.shippingTo') "> </th>
                  <th class=" " v-text=" $t('dashboard.orders.date') "> </th>
                  <th class=" " v-text=" $t('dashboard.orders.total') "> </th>
                  <th class="" v-text="$t('tables.status')"> </th>
                  <th class="" v-text=" $t('general.actions') "> </th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for=" (draft,index) in draftList" :key="index + draft.id">
                  <td>
                    <p>
                      <strong>
                        <router-link class="text-uppercase text-info" :to="'/dashboard/new-cart/' + draft.id "
                          v-text=" '#' + $t('general.draft') + '-' + draft.id.slice(-3) ">
                        </router-link>
                      </strong>
                      <br>
                    </p>
                  </td>
                  <td colspan="3"></td>
                  <td class="text-center">
                    <b-button variant="outline-info" v-text="$t('general.pending')"
                      :to="'/dashboard/new-cart/' + draft.id ">
                    </b-button>
                  </td>
                  <td></td>
                </tr> -->
                <tr v-for=" (order, index ) in orderList  " :key=" index " class=" text-center animated"
                  :class="loading.order.id == order.id ? 'fadeOut':'' ">
                  <td class=" text-left ">
                    <div v-if="order.is_from_seller">
                      <div v-if="order.status == 'pending' ">
                        <p>
                          <strong>
                            <!-- $t('general.draft') -->
                            <router-link class="text-uppercase text-info" :to="'/dashboard/new-cart/' + order.cart_id "
                              v-text=" '#' + order.number + '-' + order.store_id.slice(-3) ">
                            </router-link>
                          </strong>
                          <br>
                        </p>
                        <p v-if="order.email">
                          <small class="text-muted f-w-500" v-text=" $t('general.email') + ': ' + (order.email) ">
                          </small>
                        </p>
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-12">
                        <p class="m-0" v-if="order.shipping_address">
                          <strong>
                            <router-link class="text-uppercase" :to="'/dashboard/orders/' + order.id "
                              v-text=" '#' + order.number + '-' + order.store_id.slice(-3) ">
                            </router-link>
                          </strong>
                          <br>
                          <small class="text-muted f-w-500" v-text=" $t('tables.buyer') + ': ' + (order.shipping_address.first_name ? order.shipping_address.first_name : '' ) 
													+ ' ' + order.shipping_address.last_name ">
                          </small>
                        </p>
                      </div>
                    </div>
                  </td>

                  <td class=" ">
                    <p v-if="order.shipping_address && order.shipping_address.country.code">
                      <i class="flag-icon mr-2"
                        :class=" 'flag-icon-' + (order.shipping_address.country.code).toLowerCase() "></i>
                      <strong v-text=" order.shipping_address.country.code "></strong>
                    </p>
                  </td>

                  <td class=" ">
                    <p class=" text-truncate " v-text=" fnDate( order.created_at, CONST.DATE_FORMATS.DD_MM_YYYY ) ">
                    </p>
                  </td>

                  <td class=" ">
                    <p v-text=" fnFormatPrice( order.totals.total , order.currency )  " class=" "></p>
                  </td>

                  <td class="vertical-align-center">
                    <div v-if="order.is_from_seller"></div>
                    <div v-else>
                      <!-- Order status -->
                      <div v-if=" order.order_status == 'cancelled'">
                        <p class="text-danger ">
                          <i class="fa fa-ban mr-1"></i>
                          <span v-text=" $t('general.cancelled') "></span>
                        </p>
                      </div>
                      <div v-else>
                        <div v-if=" order.status == 'paid' ">
                          <button class="btn-status btn btn-success shadow " @click="fnOpenOrderComponent( index )"
                            v-if=" order.shipment_status == 'pending'  && order.order_status != 'in_review' ">
                            <i class=" mr-1 fa fa-receipt "></i>
                            <span v-text="$t('general.createLabel')"></span>
                          </button>
                          <button class="btn-status btn btn-outline-success bg-white text-success btn-white "
                            @click="fnOpenOrderComponent( index )"
                            v-if=" order.shipment_status == 'ready to ship' && order.order_status != 'in_review' ">
                            <i class=" mr-1 fa fa-shipping-fast"></i> <span
                              v-text="$t('general.button.viewDetails')"></span>
                          </button>
                          <button class="btn-status btn btn-outline-success bg-white text-success btn-white "
                            @click="fnOpenOrderComponent( index )"
                            v-if=" order.shipment_status == 'shipped' && order.order_status != 'in_review'">
                            <i class="fa fa-shipping-fast mr-1"></i> <span v-text="$t('general.shipped')"></span>
                          </button>
                          <p v-if=" order.shipment_status == 'delivered' " class="text-success"> <i
                              class="fa fa-check-circle mr-1"></i><span v-text="$t('general.delivered')"></span>
                          </p>
                          <p v-if=" order.shipment_status == 'information' " class="text-info"> <span
                              v-text="$t('general.information')"></span></p>
                          <p v-if=" order.shipment_status == 'cancelled' " class="text-info"> <span
                              v-text="$t('general.cancelled')"></span> </p>
                          <p v-if=" order.shipment_status == 'N/A' " class="text-info"> N/A</p>
                          <p v-if=" order.shipment_status == 'picked up' " class="text-info"> <span
                              v-text="$t('general.pickedUp')"></span> </p>
                          <p v-if=" order.shipment_status == 'out for delivery' " class="text-info">
                            <span v-text="$t('general.outForDelivery')"></span>
                          </p>
                        </div>
                        <div v-else>
                          <p class="text-muted">
                            <i class=" fa fa-hand-holding-usd mr-1 "> </i>
                            <span v-text=" $t('general.paymentPending') "></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <b-dropdown variant="success" size="sm" right :disabled="loading.order.id == order.id" no-caret>
                      <template v-slot:button-content class="m-0">
                        <i class="fa fa-ellipsis-h fa-lg"></i>
                      </template>
                      <b-dropdown-item :to="'/dashboard/orders/' + order.id ">
                        <p>
                          <i class="fa fa-external-link-alt mr-1"></i>
                          <span v-text=" $t('general.details') "></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if=" (order.order_status != 'cancelled'  && order.order_status != 'in_review' && order.status == 'paid' ) &&  (order.shipment_status == 'pending')  "
                        @click="fnOpenOrderComponent( index )">
                        <p>
                          <i class="fa fa-receipt mr-1"></i>
                          <span v-text=" $t('general.createLabel') "></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item @click="fnApiArchiveOrder(index)" v-if=" pagination.archived != null ">
                        <p>
                          <i class="fa mr-1" :class=" pagination.archived ? 'fa-folder-minus':'fa-folder-plus' "></i>
                          <span
                            v-text=" pagination.archived ? $t('general.selectOptions.archived.unfiled') : $t('tables.archive') "></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="fnGetOrderSummary(order.id)">
                        <p>
                          <i class="fa fa-print mr-1"></i>
                          <span v-text=" $t('general.button.orderSummary')"></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item :href="order.shipping_items[0].label" target="_blank"
                        v-if=" order.shipping_items[0] && order.shipping_items[0].label ">
                        <p>
                          <i class="fa fa-print mr-1"></i>
                          <span v-text=" $t('general.button.printLabel')"></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item @click="fnUpdateOrderBOL(order.shipping_items[0].bill_of_landing)"
                        v-if=" order.shipping_items[0] && order.shipping_items[0].bill_of_landing">
                        <p>
                          <i class="fa fa-print mr-1"></i>
                          <span v-text=" $t('general.button.BOL')"></span>
                        </p>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
          <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
            @input="fnApiGetOrders" align="fill" :first-text="$t('general.button.first')"
            :prev-text="$t('general.button.previous')" :next-text="$t('general.button.next')"
            :last-text="$t('general.button.last')" class="d-none d-md-flex">
          </b-pagination>
          <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
            @input="fnApiGetOrders" align="fill" class=" d-md-none"
            v-if="pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
          </b-pagination>
        </div>
      </div>
    </pull-refresh>
    <b-modal :visible="orderId != null " :title=" '' " content-class="card" header-class=" d-none" body-class="p-0"
      @hidden="fnCloserderComponent() " cancel-variant="danger" size="xl">
      <OrderComponent :orderId="orderId" ordersPage class=" animated " :class=" (orderId != null) ? 'fadeIn':'' "
        v-show="orderId != null" />
      <template v-slot:modal-footer>
        <div class="ml-auto">
          <b-button variant="none" class=" mr-2" v-text=" $t('general.button.close') " @click="fnCloserderComponent()">
          </b-button>
        </div>
      </template>
    </b-modal>


    <OrdersNewCartModal />
  </div>
</template>

<script>
  import OrderComponent from '@/components/order/order-component';
  import OrderShipment from './components/order_shipment_modal';
  import printJS from 'print-js';
  import PullRefresh from "@/components/PullRefresh.vue";
  import OrdersNewCartModal from './components/orders_new_cart_modal.vue';
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    components: {
      OrderShipment,
      OrderComponent,
      PullRefresh,
      OrdersNewCartModal,
    },
    data() {
      return ({
        orderId: null,
        orderIndex: null,
        showSuborders: false,
        showAdvancedFilters: false,
        storeList: [],
        orderList: [],
        draftList: [],
        pagination: {
          number: null,
          country: null,
          status: null,
          store_id: null,
          page: 1,
          limit: 5,
          total: null,
          archived: null,
        },
        countries: [],
        loading: {
          data: false,
          newCart: false,
          order: {
            id: null,
            remove: false,
          }
        }
      })
    },
    computed: {
      ...mapState(['mobileVersion']),
      ...mapState('order', ['orderInformation', 'shippingInformation']),
    },
    watch: {
      orderInformation() {
        this.orderList[this.orderIndex] = {
          ...this.orderInformation
        };
      },
      shippingInformation() {
        this.orderList[this.orderIndex].shipping_items[0] = {
          ...this.shippingInformation
        };
      },
      mobileVersion() {
        this.fnMobileUI();
      }
    },

    methods: {
      ...mapMutations('EcartOrders', ['changeModal']),
      fnMobileUI() {
        if (this.mobileVersion) {
          this.showAdvancedFilters = false;
        } else {
          this.showAdvancedFilters = true;
        }
      },

      fnOpenOrderComponent(index) {
        this.orderId = this.orderList[index].id;
        this.orderIndex = index;
      },
      fnCloserderComponent() {
        this.orderId = null;
        this.orderIndex = null;
      },

      async fnApiArchiveOrder(index) {
        let order = this.orderList[index];
        this.loading.order.id = order.id;
        await axios.put(`orders/archived/${order.id}`, {
          option: !order.archived,
        }).then((response) => {
          this.loading.order.remove = true;
          setTimeout(() => {
            this.loading.order.id = null;
            this.orderList.splice(index, 1);
            this.loading.order.remove = false;
          }, 400);
        }).catch((error) => {});
      },

      // PAGINATION
      fnApiGetOrders() {
        this.loading.data = true;
        let {
          store_id,
          number,
          country,
          status,
          limit,
          page,
          archived,
        } = this.$lodash.clone(this.pagination);
        let tempPagination = {
          page,
          limit,
        };

        if (archived !== null && archived !== '') {
          tempPagination.archived = archived
        };
        if (store_id !== null && store_id !== '') {
          tempPagination.store_id = store_id
        };
        if (number != null && number != '') {
          tempPagination.number = number
        };
        if (country != null && country != '') {
          tempPagination['shipping[country]'] = country
        };
        if (status !== null && status !== '') {
          tempPagination.status = status
        };

        this.$router.replace({
          query: {
            ...tempPagination,
            ...{
              country
            }
          }
        }).catch(err => {})

        axios.get('orders', {
          params: tempPagination
        }).then((response) => {
          this.orderList = response.data;
          // this.orderList = response.data.orders;
          // this.draftList = response.data.drafts;
          this.loading.data = false;
        }).catch((error) => {});
      },

      fnApiGetCountOrders() {
        this.pagination.total = 0;

        let {
          store_id,
          number,
          country,
          status,
          limit,
          page,
          archived,
        } = this.$lodash.cloneDeep(this.pagination);

        let tempPagination = {};

        if (archived !== null && archived !== '') {
          tempPagination.archived = archived
        };
        if (store_id !== null && store_id !== '') {
          tempPagination.store_id = store_id
        };
        if (number != null && number != '') {
          tempPagination.number = number
        };
        if (country != null && country != '') {
          tempPagination['shipping[country]'] = country
        };
        if (status !== null && status !== '') {
          tempPagination.status = status
        };

        axios.get('orders/count', {
          params: tempPagination
        }).then((response) => {
          this.pagination.total = response.data.count;
        }).catch((error) => {});
      },

      fnResetPagination() {
        this.pagination.page = 1;
        this.pagination.limit = this.CONST.PAGINATION[0];
        this.fnApiGetCountOrders();
        this.fnApiGetOrders();
      },
      fnApiGetStores() {
        axios.get('stores').then(async (response) => {
          if (response.data && response.data.length > 0) {
            this.storeList = response.data;
          } else {
            this.loading.data = false;
          }
        }).catch(error => {});
      },
      fnGetArchivedOrders() {
        this.pagination.archived = !this.pagination.archived;
        this.fnResetPagination()
      },

      fnGetOrderSummary(orderId) {
        axios.get(`orders/summary/${orderId}`, {
          headers: this.headers,
          responseType: 'blob',
        }).then((response) => {
          var pdfFile = new Blob([response.data], {
            type: "application/pdf"
          });
          var pdfUrl = URL.createObjectURL(pdfFile);
          printJS(pdfUrl);
        }).catch(error => {});
      },
      async fnUpdateOrderBOL(bolLink) {
        if (process.env.NODE_ENV == 'development') {
          window.open(bolLink);
        } else {
          printJS(bolLink);
        }
      },
      async fnRefresh(done) {
        await this.fnResetPagination();
        done()
      },
      // fnApiGetDrafts() {
      //   axios.get(`carts/drafts`).then((response) => {}).catch(error => {});
      // }
    },
    async mounted() {
      // this.fnApiGetDrafts();
      this.fnMobileUI();
      if (this.$route.query.status) {
        this.pagination.status = this.$route.query.status;
      }
      if (this.$route.query.storeId) {
        this.pagination.store_id = this.$route.query.storeId;
      }
      if (this.$route.query.store_id) {
        this.pagination.store_id = this.$route.query.store_id;
      }

      this.$route.query.number ? (this.pagination.number = this.$route.query.number) : false;
      this.$route.query.country ? (this.pagination.country = this.$route.query.country) : false;
      this.$route.query.status ? (this.pagination.status = this.$route.query.status) : false;
      this.$route.query.archived ? (this.pagination.archived = this.$route.query.archived) : false;

      this.countries = await this.fnApiGetCountries();
      this.fnResetPagination();
      this.fnApiGetStores();
    }
  }
</script>

<style lang="scss" scoped>
  .btn-status {
    min-width: 150px !important;
  }

  .btn-white {
    &:hover {
      color: white !important;
    }
  }

  .banner-ecart {
    // background-color: #66b327;
    // overflow: hidden;
  }

  .banner-image {
    background-color: #66b327;
    object-fit: contain;
    min-height: 80px;
    max-height: 100px;
    width: 100%;
  }
</style>