<template>
  <div class="card ">
    <div class="card-header">
      <h6>
        <span v-text="$t('dashboard.order.bol')"> </span>
        <small class="text-warning" v-text=" ' (' + $t('dashboard.order.shipping.warning') +')'"></small>
      </h6>
      
    </div>
    <div class="card-body">
      <p v-html="$t('components.trackingNumber.bolMessage')"> </p>
      <p ><strong v-html="$t('components.trackingNumber.bolWarning')"></strong> </p>
      <v-observer ref="bolForm" tag="form" @submit.prevent="fnValidateDataBOL"  >
        <div class="row mb-1 align-items-center" v-for=" (item,index) in orderInformation.items " :key=" index ">
          <div class="col-auto">
            <img v-image=" item.image_url " alt="" class="img-cover-30 border">
          </div>
          <div class="col">
            <v-validation rules="required" v-slot="{ errors }" :name=" 'Init' + item.id ">
              <input type="text" maxlength="250" v-model="item.name" class="form-control form-control-sm"
                :class=" { 'is-invalid':errors.length > 0 } ">
            </v-validation>

          </div>
        </div>
        <p class="text-right mt-3">
          <button class="btn btn-sm btn-info">
            <i class="fa fa-print mr-1"></i>
            <span v-text=" $t('general.button.BOL') "></span>
          </button>
        </p>
      </v-observer> 

    </div>
  </div>
</template>

<script>
  import printJS from 'print-js';
  export default {
    props: {
      orderInformation: {
        type: Object,
        required: true,
      }
    },
    data() {
      return { 
        shipmentDetails: {},
      }
    },
    watch: {},
    methods: {
      async fnValidateDataBOL() {
        if (await this.$refs['bolForm'].validate()) {
          this.fnUpdateOrderBOL();
        }
      },

      async fnUpdateOrderBOL() {
        let tempItemsList = [];
        for (let item of this.orderInformation.items) {
          tempItemsList.push({
            id: item.item_id,
            title: item.name
          });
        }
        await axios.post(
          `orders/${this.orderInformation.id}/shippings/${this.orderInformation.shipping_items[0].id}/billoflading`,
          tempItemsList).then((response) => {
          this.$emit('update-bol', response.data.shipping_items[0]);
          this.shipmentDetails = response.data.shipping_items[0];
          if (process.env.NODE_ENV == 'development') {
            window.open(this.shipmentDetails.bill_of_landing);
          } else {
            printJS(this.shipmentDetails.bill_of_landing);
          }
 
        }).catch(error => {});
      }
    },
    mounted() {
      this.shipmentDetails = this.orderInformation.shipping_items[0];
    }
  }
</script>

<style>

</style>